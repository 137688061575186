.rete {
  position: relative;
  width: 100%;
  height: 94vh;
  font-size: 1rem;
  background: white;
  border-radius: 1em;
  text-align: left;
  font-family: sans-serif;
  background-color: #ffffff;
  opacity: 1;
  background-image: linear-gradient(#f1f1f1 3.2px, transparent 3.2px),
    linear-gradient(90deg, #f1f1f1 3.2px, transparent 3.2px),
    linear-gradient(#f1f1f1 1.6px, transparent 1.6px),
    linear-gradient(90deg, #f1f1f1 1.6px, #ffffff 1.6px);
  background-size: 80px 80px, 80px 80px, 16px 16px, 16px 16px;
  background-position: -3.2px -3.2px, -3.2px -3.2px, -1.6px -1.6px,
    -1.6px -1.6px;
  border: none !important;
}

.container-editor{
  border: none !important;
  outline: none;
}
.editor-container {
  outline: none;
}

.export-button {
  position: absolute;
  right: 1em;
  z-index: 1000;
}

.flow-variables-button {
  position: absolute;
  right: 3.5em;
  z-index: 1000;
}

.full-screen-button {
  position: absolute;
  right: 6em;
  z-index: 1000;
}

.restore-backup-flow-button {
  position: absolute;
  right: 8.9em;
  z-index: 1000;
}

.search-node-button {
  position: absolute;
  right: 11.8em;
  z-index: 1000;
}

.auto-arrange-button {
  position: absolute;
  right: 14.9em;
  z-index: 1000;
}

.selection-shape-button {
  position: absolute;
  right: 17.9em;
  z-index: 1000;
}

.selection-button-toggle {
  position: absolute;
  right: 20.9em;
  z-index: 1000;
}

.selected-node{
  padding: 0.5em;
  border-radius: 0.5em;
  background-color: #f1f1f1;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  cursor: pointer;
}

.selected-node:hover{
  background-color: #e1e1e1;
  transition: background-color 0.3s ease;
}
.showing-backup-flows {
  display: flex;
  justify-content: center;
  margin-left: 10px;
  align-items: center;
  margin-bottom: 0.5em;
}

.load-actual-flow{
  display: flex;
  justify-content: left;
  margin-left: 1em;
  align-items: center;
}
.changed-modules{
  font-weight: bold;
}
.main-alert {
  position: absolute;
  top: 3rem;
  right: 1rem;
  z-index: 1500;
}

.mt-16 {
  margin-top: 16px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.ml-16 {
  margin-left: 16px !important;
}
.m-n-t-6{
  margin-top: -6px !important;
}
.module-tab-marker-changes-indicator{
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #da811b;
  border-radius: 50%;
  margin-right: 5px;
}

.sortable-tab {
    touch-action: none;
    user-select: none;
}

.sortable-tab:hover {
    cursor: move;
}

.sortable-tab.dragging {
    opacity: 0.8;
    background: rgba(0, 0, 0, 0.05);
}

.sortable-tab-wrapper {
    display: flex;
    align-items: center;
}

.sortable-tab-wrapper .MuiTab-root {
    opacity: 1;
}

/* Aumentamos la especificidad para el tab seleccionado */
.MuiTabs-root .sortable-tab-wrapper .MuiTab-root.Mui-selected {
    color: #1890ff !important;
    font-weight: 500 !important;
    background-color: rgba(24, 143, 255, 0.048) !important;
    border-radius: 8px 8px 0 0 !important;
    margin-top: 1px !important;
}

.sortable-tab-wrapper .MuiTab-root:hover {
    color: #40a9ff;
    background-color: rgba(64, 169, 255, 0.05);
    border-radius: 8px 8px 0 0;
}
.close-tab-button{
  cursor: pointer;
  display: flex;
  padding: 4px;
  margin-left: 4px;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  align-items: center;
  justify-content: center;
  color: #494642;
  background-color: #f1f1f1;
  padding: 2px;
}
.close-tab-button:hover{
  background-color: #e1e1e1;
  transition: background-color 0.3s ease;
}

#lasso {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

#lasso polygon {
  stroke: #6c7fe9ad;
  stroke-dasharray: 10 5;
  stroke-width: 2;
  fill: rgb(144 161 255 / 21%);
  z-index: 5;
  animation: dash linear 1s infinite;
}

@keyframes dash {
  to {
    stroke-dashoffset: 15;
  }
}

kbd {
  background-color: #f7f7f7;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: 0 1px 0 rgba(0,0,0,0.2);
  color: #333;
  display: inline-block;
  font-size: 0.85em;
  font-family: Monaco, Menlo, Consolas, monospace;
  line-height: 1;
  padding: 2px 4px;
  margin: 0 2px;
  white-space: nowrap;
}

.module-list-container{
  max-width: 78% !important;
}

@media only screen and (min-width: 800px) and (max-width: 1200px) {
  .module-list-container{
    max-width: 50% !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .module-list-container{
    max-width: 60% !important;
  }
}
@media only screen and (min-width: 1401px) and (max-width: 1600px) {
  .module-list-container{
    max-width: 70% !important;
  }
}
@media only screen and (min-width: 1601px) and (max-width: 1800px) {
  .module-list-container{
    max-width: 74% !important;
  }
}
@media only screen and (min-width: 1801px) and (max-width: 1920px) {
  .module-list-container{
    max-width: 78% !important;
  }
}
